import React, { useContext } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import CartContext from "../contexts/CartContext"

import styles from "./accessoiresItems.module.scss"
import Seo from "../components/seo"

import Layout from "../components/layout"
import SwiperSlider from "../components/swiperSlider"

const Antivols = () => {
  const { addItem } = useContext(CartContext)
  const data = useStaticQuery(graphql`
    query {
      antivol1: allStrapiAntivol(filter: { titre: { eq: "Abus Pliable" } }) {
        nodes {
          images {
            url
          }
        }
      }
      antivol2: allStrapiAntivol(
        filter: { titre: { eq: "Antivol Kryptonite Evolution" } }
      ) {
        nodes {
          images {
            url
          }
        }
      }
      antivol3: file(
        relativePath: { eq: "images/accessoires/antivols/antivol3.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      antivol4: file(
        relativePath: { eq: "images/accessoires/antivols/antivol4.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <Seo
        title="antivols pour vélos électriques"
        description="Sélection des antivols pour vélos électriques"
      />
      <main className={styles.main}>
        <h2>Antivols</h2>
        <Link to="/accessoires" className={styles.back}>
          <span>&lt;&lt; accessoires</span>
        </Link>
        <section className={styles.container}>
          <article className={styles.card}>
            <section className={styles.heading}>
              <h6>Antivol pliable Abus</h6>
              <p className={styles.price}>89 €</p>
            </section>
            <section className={styles.frame}>
              <SwiperSlider data={data.antivol1} />
            </section>
            <p className={styles.description}>
              Six tiges en acier pliables de 5mm d’épaisseur, reliées par des
              rivets spéciaux, se rabattent comme un mètre: voilà à quoi
              ressemble l’antivol pliable BORDO™ 6000. L’idée n’est pas
              nouvelle, les antivols de la famille BORDO™ d’ABUS sont devenus de
              vrais classiques, mais leur efficacité est inégalée.
            </p>
            <input
              type="submit"
              onClick={() =>
                addItem(
                  "Antivol pliable Abus",
                  "",
                  1,
                  89,
                  "accessoires/antivols/antivol1.jpg"
                )
              }
              value="Acheter"
            />
          </article>
          <article className={styles.card}>
            <section className={styles.heading}>
              <h6>Antivol U Kryptonite + câble</h6>
              <p className={styles.price}>58,00 €</p>
            </section>
            <section className={styles.frame}>
              <SwiperSlider data={data.antivol2} />
            </section>
            <p className={styles.description}>
              Avec l'antivol en U Evolution Mini-7, KRYPTONITE propose un modèle
              super léger ! Issu de sa gamme High Security, il promet un
              excellent niveau de protection, noté 7/10. C'est un antivol de
              choix pour s'équiper efficacement et sans s'encombrer en milieu
              urbain. La solidité de cet antivol en U repose notamment sur son
              système breveté de double verrouillage au niveau de la anse.
              Celle-ci est fermement reliée au socle transversal, qui intègre un
              nouveau cache-poussière empêchant les saletés de s'accumuler. Ce
              modèle est livré avec 3 clés, un antivol câble Flex et un support
              de cadre Flexframe qui permet de faciliter son transport.
            </p>
            <input
              type="submit"
              onClick={() =>
                addItem(
                  "Antivol U Kryptonite + câble",
                  "",
                  1,
                  58,
                  "accessoires/antivols/antivol2.jpg"
                )
              }
              value="Acheter"
            />
          </article>
          <article className={styles.card}>
            <section className={styles.heading}>
              <h6>Câble KryptoFlex</h6>
              <p className={styles.price}>15 €</p>
            </section>
            <section className={styles.frame}>
              <Img
                className={styles.image}
                fluid={data.antivol3.childImageSharp.fluid}
                alt="..."
              />
            </section>
            <p className={styles.description}>
              Cable antivol de 2.20m pour 1cm d'épaisseur qui se combine avec un
              U pour accrocher son vélo a un rack ou bien fixer les péripériques
              en même temps que le cadre.
            </p>
            <input
              type="submit"
              onClick={() =>
                addItem(
                  "Câble Kryptonite",
                  "",
                  1,
                  15,
                  "accessoires/antivols/antivol3.jpg"
                )
              }
              value="Acheter"
            />
          </article>
          <article className={styles.card}>
            <section className={styles.heading}>
              <h6>Antivol Kryptonite + Câble KryptoFlex</h6>
              <p className={styles.price}>45 €</p>
            </section>
            <section className={styles.frame}>
              <Img
                className={styles.image}
                fluid={data.antivol4.childImageSharp.fluid}
                alt="antivol U et son câble de la marque Kryptonite"
              />
            </section>
            <p className={styles.description}>
              Antivol Kryptonite en U pour une rigidité maximale. Facilement
              transportable grâce à sa fixation Flexframe-U sur le tube de tige
              de selle ou simplement dans un sac, facile à installer, et
              extrêmement résistant grâce à sa construction en acier trempé. Il
              n'y plus de raison de s'inquiéter. Il est idéal pour une
              utilisation urbaine sur tout type de vélo. Ajoutez-y le câble
              Kryptoflex de 122cm pour votre roue avant ou vos accessoires pour
              encore plus de sécurité.
            </p>
            <input
              type="submit"
              onClick={() =>
                addItem(
                  "Antivol U Kryptonite + câble",
                  "",
                  1,
                  45,
                  "accessoires/antivols/antivol4.jpg"
                )
              }
              value="Acheter"
            />
          </article>
        </section>
      </main>
    </Layout>
  )
}

export default Antivols
